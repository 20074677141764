import React, { useState, useRef } from "react";
import back from "../assets/png/back.png";
import AuthCode from 'react-auth-code-input';
import { navigate } from "gatsby";
import { config } from "../components";



import HelmetIndex from "../components/helmetIndex";






export default ({ location }) => {
    const [code, setCode] = useState("");
    const [navigatePage, setNavigatePage] = useState(false);
    const authInputRef = useRef(null);
    const [dataError, setDataError] = useState({
      code: false,
      response: false,

  })
  // const [disabled, setDisabled] = useState(false)
    
    const [responseError, setResponseError] = useState("")
    const storedString = typeof window !== 'undefined' &&localStorage.getItem('sport');
    
    
    const storedObject = JSON.parse(storedString);
    // const phoneNumber = storedObject?.phoneNumber?.replace(/\D+/g, '')
    // .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')??""
     let phoneNumber = storedObject?.phoneNumber?.replace(/\D+/g, '')
     phoneNumber =  phoneNumber?.substring(0,1) + "*****" + phoneNumber?.substring(phoneNumber?.length-4,phoneNumber?.length)

    // useEffect(() => {
    //   const fetchData = async () => {
    //     if(code.length===6){
    //       // send code to check
    //       const result = typeof window !== 'undefined' &&localStorage.getItem('sport');
    //       const storedObjectResult = JSON.parse(result);
    //       const newStoredObjectResult = {...storedObjectResult, verifyCode:code}

    //       const input = JSON.stringify(newStoredObjectResult)
          
    //       try {
    //         const response = await fetch(config.backendAPi+"/register/sportscalculatorpage2", {
    //           method: 'POST',
    //           headers: {
    //             'Content-Type': 'application/json'
    //           },
    //           body: input
    //         });
            
        
    //         // if (!response.ok) {
    //         //   throw new Error('Network response was not ok');
    //         // }
            
    //         const data = await response.json();
          
            
    //         if(data?.status ===0 && data?.statusText==="successful" ){
    //           typeof window !== 'undefined' && localStorage.setItem('sportResult', JSON.stringify(data));
    //           setDataError(pre=>({...pre, code: false}))
    //           navigate('/result');
            
    //         }else{
              
    //           // setResponseError(data?.statusText??"Something went wrong(102)!")
    //           setDataError(pre=>({...pre, code: true}))
    //         }
          
    //       } catch (error) {
            
    //         setResponseError("Something went wrong(103)!")
    //         setDataError(pre=>({...pre, code: true}))
          
    //       }
  
    //       setDataError(pre=>({...pre, code: true}))
    //       // if (authInputRef.current) {
    //       //   authInputRef.current.clear();
          
    //       // }
    //     }
    //   };
  
    //   fetchData();
    // }, [code]);
    const handleOnChange = async(res) => {
     
      setCode(res);
      if(res.length!==6){
        setDataError(pre=>({...pre, code: false}))
      }
      setResponseError("")
      if(res.length===6){
       
        // send code to check
        const result = typeof window !== 'undefined' &&localStorage.getItem('sport');
        const storedObjectResult = JSON.parse(result);
        const newStoredObjectResult = {...storedObjectResult, verifyCode:res}

        const input = JSON.stringify(newStoredObjectResult)
        
        try {
          const response = await fetch(config.backendAPi+"/register/sportscalculatorpage2", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: input
          });
          
      
          // if (!response.ok) {
          //   throw new Error('Network response was not ok');
          // }
          
          const data = await response.json();
        
          
          if(data?.status ===0 && data?.statusText==="successful" ){
            
            typeof window !== 'undefined' && localStorage.setItem('sportResult', JSON.stringify(data));
            setDataError(pre=>({...pre, code: false}))
            setNavigatePage(true)
           
          
          }else{
            
            // setResponseError(data?.statusText??"Something went wrong(102)!")
            setDataError(pre=>({...pre, code: true}))
          }
        
        } catch (error) {
          
          setResponseError("Something went wrong(103)!")
          setDataError(pre=>({...pre, code: true}))
        
        }

        // setDataError(pre=>({...pre, code: true}))
        // if (authInputRef.current) {
        //   authInputRef.current.clear();
        
        // }
      }
      

      // if(res.length===5){
      //     navigate('/result');
      // }
    };
    const resend= async()=>{
      setResponseError("")
    
      const input = typeof window !== 'undefined' && localStorage.getItem('sport');
      try {
        const response = await fetch(config.backendAPi+"/register/sportscalculatorpage1", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: input
        });
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
      
        
        if(data?.status ===0 && data?.statusText==="successful" ){
          
        
        }else{
          setResponseError(data?.statusText??"Something went wrong!(100)")
        }
      
      } catch (error) {
        
        setResponseError("Something went wrong(101)!")
      
      }
    }
    if(navigatePage) {
      navigate('/result');
    }

  


    return (

        <div className="has-background-white has-text-primary indexBody liftrocketCalcaulator">
            <HelmetIndex />
            <div className="calculatorBody">
                <div className="calculatorBack">
                    <img className="calculatorBackImg" onClick={(e)=>{
                        e.preventDefault()
    
   
                        navigate('');
                    }} src={back} alt="back" />
                </div>
                <div>
                <div className="codeTitle">Let’s verify it’s you</div>
                <div className="codeText">
                We’ve sent a confirmation code to the phone number {phoneNumber}. Please enter the code below.
              
                </div>
                <div className={dataError.code?"codeInput errorCode":"codeInput"}>
                    <AuthCode length={6} onChange={(e)=>handleOnChange(e)} ref={authInputRef} allowedCharacters="numeric" />
                </div>
                
                {dataError.code?<div className="codeInfo errorMessage">Wrong code, please try again</div>:<div className="codeInfo">Code will expire in 10 minutes </div>}
                {responseError?<div className="codeInfo errorMessage">{responseError}</div>:<div></div>}
                <div className="codeInfo2">
                  <div className="NotReceiveCode">Didn’t receive your code?  </div>
                  <div className={"sendCodeAgain"} onClick={()=>resend()}>Send Code Again</div>
                </div>
            </div>

            </div>
           



        </div>
    );
};